import React from 'react'
import cx from 'classnames'
import links from 'links'
import { useUser } from 'modules/user'

import Header from 'compositions/Header/Header'
import Footer from 'compositions/Footer/Footer'


type MainLayoutProps = {
  contentWrapperClassName?: string
  withHeaderBanner?: boolean
}

const MainLayout: React.CFC<MainLayoutProps> = ({ children, contentWrapperClassName, withHeaderBanner = true }) => {
  const { isLoggedIn, user } = useUser()
  // ATTN if user is authenticated we should use internal main page instead of home for every internal page
  const headerLogoLink = isLoggedIn ? links.subscription.main : undefined

  const withQueueCounter = isLoggedIn && user?.analyticsMetadata?.subscriptionStatus === 'PAYING'

  return (
    <div className="flex min-h-full flex-col">
      <Header
        logoLink={headerLogoLink}
        withBanner={withHeaderBanner}
        withQueueCounter={withQueueCounter}
        loginWithBackRedirect
      />
      <main id="main" className={cx(contentWrapperClassName, 'flex-auto')}>
        {children}
      </main>
      <Footer />
    </div>
  )
}


export default MainLayout
